.header_main_div {
  position: fixed;
  height: 100%;
  width: 100px;
  background-color: var(--grey-color);
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.menusub:hover{
  color: var(--primary-color);
}
.header_sec_div {
  margin-top: 180px;
}

.header_sec_div > div > a {
  color: var(--primary-color) !important;
  margin-bottom: 90px;
  list-style: none;
  cursor: pointer;
}

.header_sec_div > div {
  margin-bottom: 90px;
}

.header_first_div {
  margin-top: 150px;
}

.header_menu_title {
  font-size: 20px;
  font-family: "Prata", serif;
  margin-top: 10px;
}

button {
  border: 0;
  padding: 0;
  background: transparent;
  cursor: pointer;
  outline: none;
}

.menu2 {
  position: fixed;
}

.burger2 {
  z-index: 4;
  width: 30px;
  height: 30px;
  /* background-image: url(../../Media/menu.svg); */
  background-image: url(../../Media/menutwo.svg);
  background-repeat: no-repeat;
  background-position: center;
}

.burger2.open2 {
  /* background-image: url(../../Media/close.svg); */
  background-image: url(../../Media/closetwo.svg);
  position: relative;
}

.background2.open2 {
  opacity: 1;
  animation: menu-in 0.7s;
  width: 400px;
}

@keyframes menu-in {
  0% {
    clip-path: ellipse(60% 60% at 0% 50%);
  }

  100% {
    clip-path: ellipse(120% 120% at 0% 50%);
  }
}

.background2 {
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
  background: #d6d0b1;
  opacity: 0;
  transition: 0.6s;
  height: 300vh;
}

.menu2 {
  z-index: 3;
  /* top: 0; */
  /* left: 0; */
  /* display: flex; */
  /* align-items: center; */
  height: 100%;
  /* width: 100%; */
  opacity: 0;
  visibility: hidden;
  transition: 0.05s;
}

@media screen and (max-width: 576px) {
  .menu2 {
    top: 77px;
  }
}

.menu2 nav {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  width: 300px;
  text-align: start;
}

.menu2.open2 {
  opacity: 1;
  visibility: visible;
}

body .menu2 nav:hover > a {
  opacity: 0.25;
}

body .menu2 nav > a:hover {
  opacity: 1;
  color: var(--primary-color);
}

.menu2 a {
  color: black;
  font-size: 32px;
  font-family: "Euclid Circular A";
  padding: 20px 0 20px 20px;
  text-decoration: none;
  text-transform: capitalize;
  opacity: 0;
  cursor: pointer;
  transition: 0.4s;
}

.menu2.open2 a {
  opacity: 1;

  width: 100%;
}

@keyframes appear {
  0% {
    opacity: 0;
    translate: 0 30px;
  }

  100% {
    opacity: 1;
  }
}

@media screen and (max-width: 576px) {
  .header_main_div {
    position: sticky;
    width: 100%;
    height: 10%;
    justify-content: start;
    z-index: 1;
    top: 0;
    padding: 10px 0px;
  }

  .header_sec_div {
    display: none;
  }

  .header_first_div {
    display: flex;
    align-items: center;
    margin-left: 10px;
    margin-top: 0px;
  }
}

/* .dropbtn {
  color: black;
} */

/* .dropbtn:hover {
  color: var(--primary-color);
} */

/* .dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 285px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
} */

/* .dropdown-content a {
  padding: 3px 9px;
  text-decoration: none;
  display: block;
} */

/* .dropdown-content a:hover {
  background-color: #ddd;
} */

/* .dropdown:hover .dropdown-content {
  display: block;
} */

/* bnootsrtarp down start */

/* accordian css start */

.menu_boot_btn {
  background-color: #d6d0b1 !important;
  font-size: 32px;
  padding: 0px;
  border-radius: 0px !important;
}

.menu_boot_btn:focus {
  box-shadow: none !important;
}

.menu_boot_btn:hover {
  color: var(--primary-color) !important;
}

.acc_main_div {
  border: 1px solid var(--secondary-color) !important;
  border-radius: 0px !important;
  background-color: #d6d0b1 !important;
}

.accordion-button:not(.collapsed) {
  color: black !important;
  box-shadow: none !important;
  border-bottom-left-radius: 20px !important;
  border-bottom-right-radius: 20px !important;
  border-top-right-radius: 20px !important;
  border-top-left-radius: 20px !important;
  background-color: #d6d0b1 !important;
}

.menu_boot_btn:not(.collapsed) {
  background-color: #d6d0b1 !important;
  color: black !important;
  box-shadow: none !important;
  border-bottom-left-radius: 20px !important;
  border-bottom-right-radius: 20px !important;
  border-top-right-radius: 20px !important;
  border-top-left-radius: 20px !important;
}

.menu_boot_btn_two:focus {
  box-shadow: none !important;
}

.menu_boot_btn_two {
  font-weight: 500 !important;
  font-size: 20px !important;
  border-top-right-radius: 20px !important;
  border-top-left-radius: 20px !important;
  border-bottom-left-radius: 20px !important;
  border-bottom-right-radius: 20px !important;
  background-color: #d6d0b1 !important;
}

.title_name {
  font-size: 24px !important;
}

.title_name:hover {
  color: var(--primary-color) !important;
}

.accordion-body {
  background-color: #d6d0b1 !important;
}

.accordion-item {
  border: 1px solid var(--secondary-color) !important;
  border-radius: 0px !important;
  background-color: #d6d0b1 !important;
}

/* accordian css end */
