.contact_get_title {
    color: var(--primary-color);
    font-family: 'Jaldi', sans-serif;
    font-size: 90px;
    font-weight: 400;
    display: inline-block;
}

.contact_section_two {
    text-align: -webkit-center;
}

.contact_send_btn {
    border: 1px solid var(--primary-color);
    color: var(--primary-color);
    font-family: 'Jaldi', sans-serif;
    font-size: 30px;
    font-weight: 700;
    display: inline-block;
    padding: 10px 40px;
}

.contact_send_btn:hover {
    color: var(--white-color);
    border: 1px solid var(--primary-color);
    background-color: var(--primary-color);
}


.contact_send_btn:focus {
    color: var(--white-color);
    border: 1px solid var(--primary-color);
    background-color: var(--primary-color);
}



.contact_text_field {
    background-color: var(--white-color);
    border: 1px solid var(--primary-color);
    border-radius: 0px;
    font-family: 'Jaldi', sans-serif;
    font-size: 20px;
    font-weight: 400;
    border-radius: 10px;
}

.contact_text_field::placeholder {
    color: #66645D;
    font-family: 'Jaldi', sans-serif;
    font-size: 20px;
    font-weight: 400;
}

.contact_text_field:focus {
    background-color: var(--white-color);
    box-shadow: none;
    border: 1px solid var(--primary-color);
}

@media screen and (max-width:576px) {

    .contact_get_title {
        font-size: 40px;
    }

    .contact_text_field,
    .contact_text_field::placeholder {
        font-size: 20px;
    }

    .contact_send_btn {
        font-size: 1rem;
    }
}