/* SECTION ONE START */

.section_one_img {
  width: 100%;
}

/* SECTION ONE END */

/* SECTION TWO START */

.two_first_div {
  width: 100%;
  padding: 170px 0px;
  /* padding: 0px 20px; */
  justify-content: center;
  background-color: var(--secondary-color);
}

.sectiontwo_left_title {
  color: #121211;
  font-family: "Prata", serif;
  font-size: 55px;
  font-weight: 400;
}

.sectiontwo_right_title {
  color: var(--primary-color);
  font-family: "Prata", serif;
  font-size: 50px;
  font-weight: 400;
}

/* SECTION TWO END */

/* SECTION THREE START */

.hight_first_div {
  padding: 20px;
  width: 99%;
}

.section_three_img {
  width: 100%;
}

/* SECTION THREE END */

.project_logo_high {
  width: 30%;
  margin-bottom: 10px;
}

/* SECTION FOUR START */

.project_section_four {
  background-color: var(--primary-color);
  padding-top: 105px;
}

.project_four_first_title {
  position: relative;
  color: var(--secondary-two-color);
  text-align: center;
  font-size: 50px;
  font-weight: 500;
  padding-bottom: 100px;
}

.project_four_first_title::after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  width: 15%;
  left: 43%;
  border-bottom: 1px solid var(--secondary-color);
}

.project_four_second_div {
  width: 100%;
  margin-top: 70px;
  justify-content: space-around !important;
}

.four_small_titles {
  color: #f2f2f2;
  font-family: "Prata", serif;
  font-size: 45px;
  font-weight: 400;
}

.project_four_second_div2 {
  width: 100%;
  margin-top: 120px;
  padding-bottom: 50px;
  justify-content: space-around !important;
}

.four_small_titles2 {
  position: relative;
  color: #f2f2f2;
  font-family: "Prata", serif;
  font-size: 45px;
  font-weight: 400;
}

.four_small_titles_right_2 {
  position: relative;
  color: #f2f2f2;
  font-family: "Prata", serif;
  font-size: 50px;
  font-weight: 400;
}

.four_small_titles2::after {
  content: "";
  display: block;
  position: absolute;
  height: 225px;
  bottom: -50px;
  right: -40px;
  border-right: 1px solid var(--secondary-color);
}

.four_small_titles_right_2::after {
  content: "";
  display: block;
  position: absolute;
  height: 225px;
  bottom: -50px;
  left: -5%;
  border-left: 1px solid var(--secondary-color);
}

/* SECTION FOUR END */

/* SECTION FIVE START */

.section_five_img {
  width: 100%;
}

.project_section_fifth {
  background-color: var(--secondary-color);
}

/* SECTION FIVE END */

/* SECTION SIX START */

.project_section_six {
  padding: 100px 150px;
  background-color: var(--secondary-color);
}

.six_paragraph {
  color: var(--primary-color);
  text-align: center;
  font-family: "Prata", serif;
  font-size: 50px;
  font-weight: 400;
}

.six_download_title {
  margin-top: 60px;
  cursor: pointer;
  color: var(--dark-grey);
  text-align: center;
  font-family: "Prata", serif;
  font-size: 60px;
  font-weight: 400;
  border: 2px solid var(--dark-grey);
}

.six_download_title:hover {
  background-color: var(--primary-color);
  color: white;
}

.six_download_title:active {
  background-color: var(--primary-color);
  color: white;
}

/* SECTION SIX END */

@media screen and (max-width: 1024px) {
  .sectiontwo_left_title {
    font-size: 40px;
  }

  .sectiontwo_right_title,
  .four_small_titles,
  .four_small_titles2,
  .four_small_titles_right_2 {
    font-size: 33px;
  }

  .project_section_six {
    padding: 100px 30px;
  }

  .six_paragraph {
    font-size: 40px;
  }

  .six_download_title {
    font-size: 40px;
  }
}

@media screen and (max-width: 576px) {
  .sectiontwo_left_title {
    font-size: 35px;
  }

  .sectiontwo_right_title {
    font-size: 25px;
    text-align: center;
    margin-top: 20px;
  }

  .project_four_first_title {
    font-size: 35px;
  }

  .project_four_second_div {
    width: 100%;
    gap: 20px;
    text-align: center;
    --bs-gutter-x: 0rem;
  }

  .four_small_titles,
  .four_small_titles2,
  .four_small_titles_right_2 {
    font-size: 25px;
  }

  .project_four_second_div2 {
    width: 100%;
    gap: 20px;
    text-align: center;
    margin-top: 20px;
    --bs-gutter-x: 0rem;
  }

  .project_section_six {
    padding: 15px;
  }

  .six_paragraph {
    font-size: 20px;
  }

  .two_first_div {
    --bs-gutter-x: 0rem;
    padding: 100px 0px;
  }

  .six_download_title {
    font-size: 20px;
  }

  .four_small_titles2::after,
  .four_small_titles_right_2::before {
    display: none;
  }

  .project_logo_high {
    width: 60%;
  }
}

@media screen and (min-width: 576px) {
  .popup-content {
    width: 40% !important;
  }
}

.main_box {
  height: 25px;
  width: 25px;
  border: 1px solid black;
  display: flex;
  justify-content: center;
  align-items: center;
}

.active {
  height: 15px;
  width: 15px;
  background-color: #FFD700;
  border-radius: 50%;
}

.highstreet_booking {
  display: grid;
  grid-template-columns: auto auto auto auto auto auto auto auto;
  padding: 50px 20px;
}

.highstreet_booking > div {
  border-right: none !important;
}

@media only screen and (max-width:1400px) {

  .highstreet_booking {
    display: grid;
    grid-template-columns: auto auto auto auto;
  }


}

@media only screen and (max-width:1024px) {

  .highstreet_booking {
    display: grid;
    grid-template-columns: auto auto auto;
  }



}

@media only screen and (max-width:640px) {

  .highstreet_booking {
    display: grid;
    justify-content: center;
    grid-template-columns: auto;
  }


}