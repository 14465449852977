/* SECTION ONE START */

.homepage_main_div_second {
  /* background: url(../../Media/mainbackt.png); */
  background: url(../../Media/iris/threeblur.png);
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
}

.home_main_logo {
  margin-top: 20px;
  width: 20%;
}

.homepage_main_sec {
  text-align: end;
  /* padding-top: 60px; */
  margin-right: 95px;
  margin-bottom: 70px;
}

.home_section_one {
  display: flex;
  justify-content: space-around;
  padding-bottom: 107px;
}

.sec_one_main_title {
  margin-bottom: 20px;
}

.sec_one_main_title > div {
  color: var(--primary-color);
  font-size: 110px;
  font-family: "Prata", serif;
  font-weight: 400;
  white-space: nowrap;
  line-height: 1em;
}

.sec_one_main_para {
  font-family: "Jaldi", sans-serif;
  font-size: 20px;
  font-weight: 400;
  color: var(--white-color);
  line-height: 1em;
}

/* SECTION ONE END*/

/* SECTION TWO START*/

.homepage_section_two {
  padding: 100px;
  background-color: var(--secondary-color);
}

.section_two_main_title {
  color: var(--primary-color);
  font-family: "Prata", serif;
  font-size: 100px;
  font-weight: 400;
  line-height: normal;
  text-align: center;
}

.two_award_img {
  width: 80%;
}

.award_two {
  margin-top: 150px;
}

.two_title_right_up {
  color: var(--primary-color);
  font-family: "Jaldi", sans-serif;
  font-size: 90px;
  font-weight: 400;
  display: inline-block;
  line-height: 1em;
}

.two_title_right_down {
  display: inline-block;
  color: var(--green-color);
  font-family: "Jaldi", sans-serif;
  font-size: 60px;
  font-weight: 400;
  line-height: 1em;
}

/* SECTION TWO END*/

/* SECTION THREE START*/

.home_title_name {
  font-size: 3em;
  cursor: pointer;
  color: var(--white-color);
}

.homepage_section_three {
  padding: 50px;
  background-color: var(--primary-color);
}

.three_sec_one_main_title {
  color: #dad4b5;
  font-size: 90px;
  font-weight: 400;
  font-family: "Prata", serif;
}

.three_sec_one {
  display: flex;
  justify-content: space-between;
  position: relative;
}

.three_one_line {
  margin-top: 30px;
  width: 20px;
  background: #dad4b5;
  height: 325px;
}

.logo_two {
  bottom: 0;
  position: absolute;
  width: 40%;
}

.three_right_img {
  width: 100%;
}

.three_sec_two {
  margin-top: 35px;
  width: 99%;
}

.three_sect_two_last_title {
  font-size: 50px;
  font-weight: 500;
  font-family: "Prata", serif;
  color: var(--secondary-two-color);
}

.three_sect_two_last_title > div {
  color: var(--yellow-color);
  font-family: Inter;
  font-size: 20px;
  font-weight: 700;
  margin-top: 85px;
  cursor: pointer;
}

.three_section_two {
  margin-top: 87px;
  width: 99%;
}

.three_two_title {
  color: var(--secondary-two-color);
  font-size: 50px;
}

.three_two_four_imag {
  width: 100%;
}

.three_two_five_img {
  width: 100%;
}

.three_shop_main_div {
  display: flex;
  align-items: end;
}

.three_two_shops_title {
  color: var(--secondary-two-color);
  font-family: "Prata", serif;
  font-weight: 400;
  font-size: 50px;
}

.three_view_title {
  color: var(--yellow-color);
  font-family: Inter;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 30px;
  cursor: pointer;
}

/* SECTION THREE END*/

/* SECTION FOUR START*/

.homepage_section_four {
  background-color: var(--secondary-color);
}

.homepage_section_four > .four_first_title_main {
  display: flex;
  justify-content: center;
  padding: 100px 0px;
}

.four_first_title {
  font-family: "Prata", serif;
  font-size: 50px;
  font-weight: 400;
  color: var(--primary-color);
}

.four_second_div {
  padding: 50px;
}

.second_2 {
  display: grid;
  grid-template-columns: auto auto auto auto;
  justify-items: center;
  gap: 10px;
}

.four_second_div_main_title {
  color: var(--primary-color);
  font-family: "Prata", serif;
  font-size: 100px;
  font-weight: 400;
}

.caption_home {
  color: var(--dark-grey);
  font-family: "Prata", serif;
  font-size: 50px;
  font-weight: 400;
}

/* SECTION FOUR END*/

/* Splide css start*/

.testi_left_side {
  font-family: "Prata", serif;
  font-weight: 500;
}

.testi_div_two {
  display: flex;
  align-items: center;
}

.testi_div_two i {
  color: var(--primary-color);
  margin-right: 10px;
}

.testi_div_two span {
  color: var(--dark-grey);
  font-size: 20px;
}

.testi_contact_btn {
  font-weight: 500;
  font-family: "Inter", sans-serif;
  color: var(--white-color);
  background-color: var(--primary-color);
  margin-top: 30px;
}

.testi_contact_btn:hover {
  color: var(--white-color);
  background-color: var(--primary-color);
}

.splide_detail_div {
  padding: 20px;
}

.splide__pagination {
  display: none !important;
}

.testi_cap {
  font-size: 1.5rem;
  font-family: "Inter", sans-serif;
}

.testi_rev_name {
  font-size: 1.5rem;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  margin-top: 10px;
}

.splide_track {
  background-color: #f6f9fc;
  padding: 10px;
  border-radius: 50px;
}

.splide {
  padding: 20px !important;
}

.splide__arrows {
  position: relative !important;
  top: 30px;
}

.carousal_btn {
  border-radius: 50% !important;
  border: 1px solid var(--primary-color) !important;
}

.carousal_btn i {
  color: var(--primary-color);
}

.carousal_btn:hover {
  background-color: var(--primary-color);
}

.carousal_btn:hover i {
  color: var(--white-color);
}

.splide__arrow:focus-visible {
  outline: none !important;
}

.splide__arrow--next {
  margin-left: 10px;
  left: 60px !important;
}

/* Splide css end */

@media screen and (max-width: 1024px) {
  .homepage_section_two {
    padding: 50px;
  }

  .home_title_name {
    font-size: 2em;
  }
}

@media screen and (max-width: 1400px) {

  .second_2 {
    grid-template-columns: auto auto;
  }

  .sec_one_main_title > div {
    font-size: 80px;
  }

  .section_two_main_title {
    font-size: 80px;
  }

  .two_title_right_up {
    font-size: 70px;
  }

  .two_title_right_down {
    font-size: 50px;
  }

  .three_sec_one_main_title {
    font-size: 80px;
  }

  .three_sect_two_last_title {
    font-size: 25px;
  }

  .three_two_title {
    font-size: 40px;
  }

  .three_two_shops_title {
    font-size: 25px;
  }

  .three_view_title {
    margin-bottom: 0px;
  }

  .homepage_section_three {
    /* padding: 110px 0px 110px 30px; */
  }

  .four_second_div_main_title {
    font-size: 55px;
  }

  .caption_home {
    font-size: 30px;
  }

  .two_right_title2 {
    font-size: 40px !important;
  }

  .two_right_paragraph2 {
    font-size: 20px;
  }
}

@media screen and (max-width: 576px) {



  .caption_home {
    font-size: 20px;
  }

  .four_second_div {
    padding: 10px;
  }

  .homepage_section_three {
    padding: 10px;
  }
  .homepage_main_sec > img {
    width: 50%;
  }

  .homepage_main_sec {
    padding: 10px;
    margin-right: 0px;
  }

  .home_section_one {
    justify-content: start !important;
    margin-left: 10px !important;
  }

  .sec_one_main_title > div {
    font-size: 43px !important;
  }

  .four_second_div_main_title {
    font-size: 29px !important;
  }

  .section_two_main_title {
    font-size: 35px;
  }

  .two_title_right_up {
    font-size: 30px;
  }

  .two_title_right_down {
    font-size: 20px;
  }

  .award_two {
    margin-top: 20px;
  }

  .three_one_line {
    display: none;
  }

  .three_sec_one {
    display: block;
  }

  .three_sec_one_main_title {
    font-size: 35px;
  }

  .logo_two {
    width: 90%;
    position: relative;
    margin-top: 10px;
  }

  .three_sec_two {
    gap: 20px;
    width: 100%;
  }

  .three_section_two {
    gap: 20px;
  }

  .three_shop_main_div {
    display: block;
  }

  .four_first_title {
    font-size: 22px !important;
  }

  .four_second_div {
    margin-top: 0px;
  }

  .homepage_main_div_second {
    height: auto;
  }

  .sec_one_main_para {
    font-size: 15px;
    line-height: 15px;
  }

  .homepage_section_two {
    padding: 20px;
  }

  .homepage_section_two2 {
    padding: 20px !important;
  }
}

@media screen and (max-width: 768px) {

  .second_2 {
    grid-template-columns: auto;
  }

  .sec_one_main_title > div {
    font-size: 60px;
  }

  .home_section_one {
    display: flex;
    justify-content: start;
    margin-left: 50px;
  }

  .home_main_logo {
    width: 50%;
  }

  .section_two_main_title,
  .three_sec_one_main_title {
    font-size: 50px;
  }

  .two_title_right_up {
    font-size: 40px;
  }

  .two_title_right_down {
    font-size: 30px;
  }

  .three_sect_two_last_title {
    font-size: 20px;
  }

  .three_sect_two_last_title > div {
    margin-top: 10px;
    font-size: 15px;
  }

  .three_two_title {
    font-size: 25px;
  }

  .three_two_shops_title {
    font-size: 20px;
  }

  .three_view_title {
    font-size: 15px;
  }

  .homepage_section_four > .four_first_title_main {
    padding: 40px 10px;
  }

  .four_first_title {
    font-size: 2;
  }

  .four_second_div_main_title {
    font-size: 40px;
  }
}

/* doctor card css start */

.main_card {
  box-sizing: border-box;
  width: 300px;
  cursor: pointer;
}

.photo_div {
  display: flex;
  justify-content: center;
  transform: translateY(50px);
}

.main_photo {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 1px solid rgba(192, 201, 213, 0.5);
  background: white;
  padding: 5px;
}

.card_detail {
  padding: 10px;
  padding-top: 50px;
  background-color: #ffffff;
  border: 1px solid rgba(192, 201, 213, 0.5);
  border-radius: 10px;
  height: 200px;
}

.card_name {
  color: var(--primary-color);
  font-family: "Prata", serif;
  font-weight: 400;
  font-size: 30px;
  text-align: center;
}

.card_profession {
  text-align: center;
  color: var(--dark-grey);
  font-family: "Prata", serif;
  font-size: 19px;
  font-weight: 400;
}

/* docotr card css end */
