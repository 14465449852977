/* SECTION ONE START */

.about_section_one {
    background: linear-gradient(to bottom,
            #952323 0%,
            #952323 70%,
            #d6d0b1 70%,
            #d6d0b1 100%);
}

.about_main_logo {
    text-align: center;
    padding-top: 60px;
    /* padding-right: 60px; */
}

.image_main {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 105px;
}

.about_one_img {
    width: 80%;
}

.about_logo {
    width: 20%;
}



/* SECTION ONE END */


/* SECTION TWO START */

.about_section_two {
    background-color: var(--secondary-color);
    text-align: center;
}

.section_two_title_one {
    padding-top: 150px;
    color: var(--primary-color);
    font-family: 'Prata', serif;
    font-size: 50px;
    font-weight: 400;
}

.section_two_title_two {
    margin-top: 80px;
    color: var(--primary-color);
    text-align: center;
    font-family: 'Prata', serif;
    font-size: 110px;
    font-weight: 400;
}

/* SECTION TWO END */



/* SECTION THREE START */

.about_section_three {
    background-color: var(--primary-color);
}


.section_three_title_one {
    padding-top: 80px;
    color: var(--yellow-color);
    text-align: center;
    font-family: 'Prata', serif;
    font-size: 110px;
    font-weight: 400;
}

.section_three_title_one>div {
    margin-top: 80px;
}



.section_three_title_one:nth-child(1) {
    padding-top: 10px;
}

.three_second_main {
    display: flex;
    justify-content: center;
    margin-top: 200px;
    position: relative;
}

.three_second_div {
    color: var(--secondary-two-color);
    text-align: center;
    font-family: 'Prata', serif;
    font-size: 100px;
    font-weight: 500;
}

.hr {
    content: "";
    position: absolute;
    border: 1px solid var(--secondary-two-color);
    width: 225px;
}

.hr2 {
    content: "";
    position: absolute;
    border: 1px solid var(--secondary-two-color);
    width: 225px;
    bottom: 0;
}

.three_third_main {
    display: flex;
    justify-content: center;
    /* margin-top: 140px; */
}

.third_main_two {
    width: 99%;
    margin-top: 100px;
}

.three_third_main_two {
    display: flex;
    justify-content: center;
    /* margin-top: 140px; */
    padding-bottom: 100px;
}

.third_title_right {
    color: #F2F2F2;
    font-family: 'Prata', serif;
    font-size: 40px;
    font-weight: 400;
    display: flex;
    justify-content: center;
    border-left: 1px solid var(--secondary-two-color);
}

.cirtificate_image {
    width: 80%;
}

.about_img_2{
    width: 50%;
}

/* SECTION THREE END */


/* old section start  */

.homepage_section_two2 {
    background: var(--secondary-color);
    padding: 80px 60px 80px 0px;
    display: flex;
    gap: 60px;
}

.section_two_right2 {
    padding: 20px;
}


.section_two_left2>img {
    width: 100%;
}

.two_right_title2 {
    color: var(--primary-color);
    font-size: 80px;
    font-family: 'Prata', serif;
    font-weight: 400;
}

.two_right_paragraph2 {
    color: var(--green-color);
    font-size: 22px;
}

.two_right_works2 {
    margin-top: 80px;
}

.works_first2 {
    border-bottom: 1px solid var(--dark-grey);
    padding-bottom: 10px;
    cursor: pointer;
    margin-top: 55px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.work_title {
    font-family: 'Inter', sans-serif;
    font-size: 20px;
    font-weight: 400;
}


.right_about_title {
    color: var(--primary-color);
    font-family: 'Inter', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    margin-top: 44px;
    cursor: pointer;
}

svg {
    fill: black;
}

.works_first2:hover svg {
    fill: white;
    background-color: var(--black-color);
}

/* old section end */



@media screen and (max-width:576px) {

    .about_logo {
        width: 50%;
    }

    .about_main_logo {
        padding-top: 30px;
        /* padding-right: 10px; */
    }

    .section_two_title_two,
    .section_three_title_one {
        font-size: 50px;
    }

    .section_three_title_one>div {
        margin-top: 40px;
    }

    .section_three_title_one {
        margin-top: 0px;
    }

    .section_two_title_one {
        font-size: 20px;
        padding-top: 30px;
    }

    .three_second_main {
        margin-top: 85px;
    }

    .three_second_div {
        font-size: 50px;
    }

    .hr,
    .hr2 {
        width: 160px;
    }


    .third_main_two {
        width: 100%;
    }

    .third_title_right {
        border-left: none;
    }

    .third_title_right {
        font-size: 25px;
        text-align: center;
    }

    .three_third_main_two {
        /* margin-top: 80px; */
    }
}

@media screen and (max-width:992px) {

    .section_two_title_one {
        font-size: 30px;
    }

    .section_two_title_two,
    .section_three_title_one {
        font-size: 60px;
    }

}


@media screen and (max-width:768px) {

    .third_title_right {
        border-left: none;
    }

    .third_title_right {
        text-align: center;
    }
}




