.footer_first_div {
  padding: 110px;
}

.main_footer {
  position: relative;
  background-color: var(--primary-color);
}

.footer_first_section {
  border-bottom: 1px solid #ae865b;
  padding-bottom: 100px;
}

.footer_first_paragraph {
  color: var(--secondary-color);
  font-size: 40px;
  font-weight: 400;
  font-family: "Jaldi", sans-serif;
}

.footer_first_field {
  background-color: var(--primary-color);
  border: 3px solid var(--secondary-color);
  border-radius: 0px;
  font-family: "Jaldi", sans-serif;
  font-size: 40px;
  font-weight: 400;
}

.footer_first_field::placeholder {
  color: #66645d;
  font-family: "Jaldi", sans-serif;
  font-size: 40px;
  font-weight: 400;
}

.footer_first_field:focus {
  background-color: var(--primary-color);
  box-shadow: none;
  border: 3px solid var(--secondary-color);
}

.footer_second_section {
  padding: 120px 0px;
}

.footer_second_section>div>img {
  width: 70%;
}

.footer_third_one {
  text-decoration-line: underline;
}

.footer_third_one,
.footer_third_two,
.footer_third_three {
  color: var(--yellow-color);
  font-family: "Jaldi", sans-serif;
  font-size: 24px;
  font-weight: 400;
  cursor: pointer;
}

.footer_third_three {
  text-decoration: none !important;
  display: flex;
  align-items: start;
}

/* .footer_fourth_section {
    margin-top: 100px;
} */

/* .fourth_first>span {
    color: #fff;
    font-family: 'Inter', sans-serif;
    font-size: 20px;
    margin-right: 50px;
    cursor: pointer;
} */

.fourth_second {
  text-align: end;
}

.fourth_second div>i {
  color: #fff;
  cursor: pointer;
}

.fourth_second div>a {
  color: #fff;
  cursor: pointer;
}

.footer_first_field::placeholder {
  color: white;
}

@media screen and (max-width: 1200px) {
  .footer_first_div {
    padding: 50px;
  }
}

@media screen and (max-width: 576px) {
  .footer_first_div {
    padding: 20px;
  }

  /* .fourth_first>span {
        margin-right: 0px;
    } */

  .footer_first_field {
    font-size: 20px;
    margin-top: 10px;
  }

  .footer_first_section {
    padding-bottom: 35px;
  }

  .footer_second_section {
    padding: 65px 0px;
    gap: 20px;
  }

  /* .fourth_first {
        display: grid;
    } */

  .footer_third_section {
    gap: 20px;
  }

  /* .footer_fourth_section {
        margin-top: 60px;
    } */

  .fourth_second {
    text-align: start;
    margin-top: 30px;
  }

  .footer_first_field::placeholder {
    font-size: 20px;
  }
}

@media screen and (max-width: 768px) {
  .footer_first_paragraph {
    font-size: 25px;
  }

  .footer_second_section {
    gap: 20px;
  }
}

@media screen and (max-width: 992px) {
  .footer_first_paragraph {
    font-size: 25px;
  }

  /* .fourth_first>span {
        margin-right: 25px;
    } */

  .fourth_second>a {
    margin-right: 25px;
  }
}

.footer_last_sec {
  background-color: var(--grey-color);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 10px;
}

.footer_title_last {
  font-size: 20px;
  font-weight: 500;
  font-family: "Montserrat", sans-serif;
}

.footer_kodenauts_link {
  list-style: none;
  text-decoration: none;
  color: black;
}