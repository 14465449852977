:root {
  --primary-color: #952323;
  --secondary-color: #D6D0B1;
  --secondary-two-color: #F2E8C6;
  --yellow-color: #D2DE32;
  --grey-color: #D9D9D9;
  --green-color: #016A70;
  --black-color: #000000;
  --white-color: #FFFFFF;
  --dark-grey: #999999;

}

html {
  scroll-behavior: smooth;
}

/* font-family: 'Jaldi', sans-serif; */

/* font-family: 'Prata', serif; */

/* font-family: 'Inter', sans-serif; */


body {
  scroll-behavior: smooth;
  transition: 0.9;
}

::-webkit-scrollbar-thumb {
  background: #952323 !important;
  border-radius: 10px !important;
}


::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background-color: transparent !important;
}




.screen_main_div {
  display: flex;
}



.pages_main_div {
  width: 100%;
  margin-left: 100px;
}

@media screen and (max-width:576px) {

  .screen_main_div {
    display: block;
  }

  .pages_main_div {
    margin-left: 0px;
    /* padding-top: 10%; */
  }

}





.scroll_up_btn {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 9;
  height: 50px;
  width: 50px;
  background-color: white;
  border: 1px solid var(--primary-color);
  border-radius: 50%;
}

.scroll_up_btn:hover {
  color: white;
  background-color: var(--primary-color);
  border-color: white;
}


/* 
#scroll-progress {
  position: fixed;
  top: 0;
  width: 0%;
  height: 4px;
  background: var(--primary-color);
  z-index: 10000;
}
 */



.treeland_coming_title {
  font-family: 'Prata', serif;
  font-size: 45px;
}