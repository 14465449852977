/* SECTION ONE START */

.iris_section_one_img {
  width: 100%;
}

/* SECTION ONE END */

/* SECTION TWO START */

.iris_two_first_div {
  width: 100%;
  padding: 170px 0px;
  /* padding: 0px 20px; */
  justify-content: center;
  background-color: var(--secondary-color);
}

.iris_sectiontwo_left_title {
  color: #121211;
  font-family: "Prata", serif;
  font-size: 55px;
  font-weight: 400;
}

.iris_sectiontwo_right_title {
  color: var(--primary-color);
  font-family: "Prata", serif;
  font-size: 50px;
  font-weight: 400;
}


.btn_360 {
  text-decoration: none;
  list-style-type: none !important;
  color: white;
  background-color: var(--primary-color);
  border-radius: 50px;
  padding: 10px;
}

/* SECTION TWO END */

/* SECTION THREE START */

.iris_paragraph {
  font-family: "Inter", sans-serif;
}

.iris_section_three_img {
  width: 80%;
  /* border-radius: 20px; */
}

.iris_section_first {
  padding: 20px;
  width: 99%;
}

/* SECTION THREE END */

/* SECTION FOUR START */

.iris_project_section_four {
  background-color: var(--primary-color);
  padding-top: 105px;
}

.iris_project_four_first_title {
  position: relative;
  color: var(--secondary-two-color);
  text-align: center;
  font-size: 50px;
  font-weight: 500;
  padding-bottom: 100px;
}

.iris_project_four_first_title::after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  width: 15%;
  left: 43%;
  border-bottom: 1px solid var(--secondary-color);
}

.iris_project_four_second_div {
  width: 100%;
  margin-top: 70px;
  justify-content: space-around !important;
}

.iris_four_small_titles {
  color: #f2f2f2;
  font-family: "Prata", serif;
  font-size: 45px;
  font-weight: 400;
}

.iris_project_four_second_div2 {
  width: 100%;
  margin-top: 120px;
  padding-bottom: 50px;
  justify-content: space-around !important;
}

.iris_four_small_titles2 {
  position: relative;
  color: #f2f2f2;
  font-family: "Prata", serif;
  font-size: 45px;
  font-weight: 400;
}

.iris_four_small_titles_right_2 {
  position: relative;
  color: #f2f2f2;
  font-family: "Prata", serif;
  font-size: 50px;
  font-weight: 400;
}

.iris_four_small_titles2::after {
  content: "";
  display: block;
  position: absolute;
  height: 225px;
  bottom: -50px;
  right: -40px;
  border-right: 1px solid var(--secondary-color);
}

.iris_four_small_titles_right_2::after {
  content: "";
  display: block;
  position: absolute;
  height: 225px;
  bottom: -50px;
  left: -5%;
  border-left: 1px solid var(--secondary-color);
}

/* SECTION FOUR END */

/* SECTION FIVE START */

.iris_section_five_img {
  width: 100%;
}

.iris_project_section_fifth {
  background-color: var(--secondary-color);
}

/* SECTION FIVE END */

/* SECTION SIX START */

.iris_project_section_six {
  padding: 100px 150px;
  background-color: var(--secondary-color);
}

.iris_six_paragraph {
  color: var(--primary-color);
  text-align: center;
  font-family: "Prata", serif;
  font-size: 50px;
  font-weight: 400;
}

.iris_six_download_title {
  margin-top: 60px;
  cursor: pointer;
  color: var(--dark-grey);
  text-align: center;
  font-family: "Prata", serif;
  font-size: 60px;
  font-weight: 400;
  border: 2px solid var(--dark-grey);
}

.iris_six_download_title:hover {
  background-color: var(--primary-color);
  color: white;
}

.iris_six_download_title:active {
  background-color: var(--primary-color);
  color: white;
}

/* SECTION SIX END */

@media screen and (max-width: 1024px) {
  .iris_sectiontwo_left_title {
    font-size: 40px;
  }

  .iris_sectiontwo_right_title,
  .iris_four_small_titles,
  .iris_four_small_titles2,
  .iris_four_small_titles_right_2 {
    font-size: 33px;
  }

  .iris_project_section_six {
    padding: 100px 30px;
  }

  .iris_six_paragraph {
    font-size: 40px;
  }

  .iris_six_download_title {
    font-size: 40px;
  }
}

@media screen and (max-width: 576px) {
  .iris_sectiontwo_left_title {
    font-size: 35px;
  }

  .iris_sectiontwo_right_title {
    font-size: 25px;
    text-align: center;
    margin-top: 20px;
  }

  .iris_project_four_first_title {
    font-size: 35px;
  }

  .iris_project_four_second_div {
    width: 100%;
    gap: 20px;
    text-align: center;
    --bs-gutter-x: 0rem;
  }

  .iris_four_small_titles,
  .iris_four_small_titles2,
  .iris_four_small_titles_right_2 {
    font-size: 25px;
  }

  .iris_project_four_second_div2 {
    width: 100%;
    gap: 20px;
    text-align: center;
    margin-top: 20px;
    --bs-gutter-x: 0rem;
  }

  .iris_project_section_six {
    padding: 15px;
  }

  .iris_six_paragraph {
    font-size: 20px;
  }

  .iris_two_first_div {
    --bs-gutter-x: 0rem;
    padding: 100px 0px;
  }

  .iris_six_download_title {
    font-size: 20px;
  }

  .iris_four_small_titles2::after,
  .iris_four_small_titles_right_2::before {
    display: none;
  }

  .iris_project_logo {
    width: 60%;
  }
}

.iris_gallery_tab {
  font-family: "Inter", sans-serif;
  font-size: 20px;
  cursor: pointer;
  padding: 10px;
  font-weight: 500;
  margin-right: 10px;
}

.iris_gallery_tab:hover {
  color: var(--primary-color);
}

.iris_active_gallery_tab {
  font-family: "Inter", sans-serif;
  font-size: 20px;
  cursor: pointer;
  padding: 10px;
  font-weight: 500;
  background: var(--primary-color);
  color: var(--white-color);
  border-radius: 50rem;
  margin-right: 10px;
}

.iris_galley_images {
  width: 100%;
  border-radius: 5px;
  height: 90%;
}

.iris_galley_images_floor {
  width: 100%;
  border-radius: 5px;
  height: 90%;
}

.iris_gallery_main>div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.iris_gallery_sec_two {
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 430px) {
  .iris_gallery_sec_two {
    display: block;
  }

  .iris_active_gallery_tab {
    width: 40%;
    text-align: center;
  }

  .iris_gallery_tab {
    width: 50%;
  }
}

/* @media screen and (max-width:576px) {

    .popup-content {
        width: 80% !important;
    }
} */



.ff {
  display: grid;
  grid-template-columns: repeat(9, 1fr);
  padding: 10px;
}

.iris_booking {
  display: flex;
  justify-content: space-between;
  padding: 50px 0px;
}

@media only screen and (max-width:992px) {

  .iris_booking {
    display: block;
  }

  .iris_booking>div {
    border-right: none !important;
  }
}


@media only screen and (max-width:1400px) {

  .ff {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
  }
}

@media only screen and (max-width:1200px) {

  .ff {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
  }
}

@media only screen and (max-width:576px) {

  .ff {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
}